
import { defineComponent, ref, computed, reactive } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import MappingVariant from './components/MappingVariant.vue'
import MappingOption from './components/MappingOption.vue'
import userProductRepositories from '@/repositories/userProductRepositories'
import userCatalogRepositories from '@/repositories/userCatalogRepositories'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    sectionLayoutContent,
    MappingVariant,
    MappingOption,
  },
  setup() {
    const breadcrumbs = ['Orders', 'Mapping']

    const content = computed(() => {
      return { breadcrumbs }
    })

    const state = reactive({
      options: ref([
        {
          value: 'option',
          label: 'Mapping with option',
        },
        {
          value: 'variant',
          label: 'Mapping with variant',
        },
      ]),
      value: 'option',
      catalogTypeNumber: 1,
      product: ref(),
      catalogs: ref<any[]>([]),
      isLoading: true,
    })

    const route = useRoute()
    const info = JSON.parse(localStorage.getItem('info') || '')
    const { getProductById } = userProductRepositories()
    const { getCatalogs } = userCatalogRepositories()

    const loadData = async () => {
      state.isLoading = true
      let { status, data } = await getProductById(route?.params?.id)
      if (status === 200 || status === 201) {
        state.product = data as any

        if (state.product.mappingData) {
          if (state.product.mappingData.type === 'mappingOption') {
            state.value = 'option'
          } else {
            state.value = 'variant'
          }
        }
      }
      let res = await getCatalogs({
        page: 1,
        limit: 10000,
        basecost: info?.sellerTeam?.basecost?.id,
      })
      state.catalogs = res as any[]
      state.isLoading = false
    }

    loadData()

    return {
      content,
      state,
    }
  },
})
