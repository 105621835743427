
import { defineComponent, ref, watch, reactive } from 'vue'
import UploadDesign from './UploadDesign.vue'
import useMappingReponsitories from '@/repositories/useMappingReponsitories'
import { imageUrl } from '@/utils/image'
import { useNotification } from '@/composables'
import SlotError from '@/components/common/SlotError.vue'

export default defineComponent({
  components: {
    UploadDesign,
    SlotError,
  },
  props: {
    product: {
      type: Object,
    },
    catalogs: {
      type: Array,
    },
  },
  setup(props) {
    const state = reactive({
      catalogTypeNumber: 1,
      tableData: [
        {
          variant: 'Pack 3 Black, Blue, White - XL',
          code: 'TS-BXL',
        },
        {
          variant: 'Pack 3 Black',
          code: 'TS-BXL',
        },
      ],
      mappingData: ref<any[]>([
        {
          quantity: 1,
          color: '',
          size: '',
          catalogId: '',
          mockupGallery: [],
        },
      ]),
      variantMapping: {
        _id: '',
        option1: ref<string>(''),
        option2: ref<string>(''),
        option3: ref<string>(''),
        title: ref<string>(''),
        sku: ref<string>(''),
        catalogs: ref<any[]>([
          {
            catalogName: ref<string>(''),
            catalogProductCode: ref<string>(''),
            catalogId: ref<string>(''),
            quantity: ref<number>(1),
            designs: [
              {
                designSrc: ref<string>(''),
                mockupSrc: ref<string>(''),
                position: ref<string>(''),
                name: ref<string>(''),
                width: ref<string>(''),
                height: ref<string>(''),
                template: ref<string>(''),
              },
            ],
            catalogVariants: [
              {
                name: ref<string>(''),
                code: ref<string>(''),
                value: ref<string>(''),
                productOption: ref<string>(''),
              },
            ],
          },
        ]),
      },
      variants: [
        {
          option1: ref<string>(''),
          option2: ref<string>(''),
          option3: ref<string>(''),
          title: ref<string>(''),
          sku: ref<string>(''),
          catalogs: [
            {
              catalogName: ref<string>(''),
              catalogProductCode: ref<string>(''),
              catalogId: ref<string>(''),
              quantity: ref<number>(1),
              designs: [
                {
                  designSrc: ref<string>(''),
                  mockupSrc: ref<string>(''),
                  position: ref<string>(''),
                  name: ref<string>(''),
                  width: ref<string>(''),
                  height: ref<string>(''),
                  template: ref<string>(''),
                },
              ],
              catalogVariants: [
                {
                  name: ref<string>(''),
                  code: ref<string>(''),
                  value: ref<string>(''),
                  productOption: ref<string>(''),
                },
              ],
            },
          ],
        },
      ],
      variant: ref(),
      catalog: ref(),
      catalogId: ref(),
      designIndex: 0,
      isLoadingAction: ref<boolean>(false),
    })

    const { success, error } = useNotification()
    const { onMappingProduct } = useMappingReponsitories()

    if (props.product) {
      if (
        props.product.mappingData &&
        props.product.mappingData.type === 'mappingVariant'
      ) {
        state.variantMapping = { ...props.product.mappingData.data }
      } else {
        state.variantMapping =
          props.product.variants && props.product.variants.length > 0
            ? { ...props.product.variants[0] }
            : null
        let catalog = {
          catalogName: '',
          catalogProductCode: '',
          catalogId: '',
          quantity: 1,
          designs: [],
          catalogVariants: [],
        }
        if (
          state.variantMapping.catalogs &&
          state.variantMapping.catalogs.length === 0
        ) {
          state.variantMapping.catalogs = [
            ...state.variantMapping.catalogs,
            catalog,
          ]
        }
      }
    }

    watch(state.variantMapping, () => {
      console.log('state.variantMapping', state.variantMapping)
    })

    const onChangeCatalog = () => {
      if (props.catalogs) {
        state.catalog = props.catalogs.find(
          (c: any) => c._id === state.catalogId
        )
      }
    }

    const onChangeCatalogNumber = () => {
      let length = state.variantMapping.catalogs.length
      if (length < state.catalogTypeNumber) {
        for (let index = 0; index < state.catalogTypeNumber - length; index++) {
          let catalog = {
            catalogName: '',
            catalogProductCode: '',
            catalogId: '',
            quantity: 1,
            designs: [],
            catalogVariants: [],
          }
          state.variantMapping.catalogs = [
            ...state.variantMapping.catalogs,
            catalog,
          ]
        }
      } else {
        state.variantMapping.catalogs.length = state.catalogTypeNumber
      }
    }

    const getVariant = (data: any) => {
      if (props.catalogs) {
        const catalog =
          props?.catalogs?.find((ca: any) => ca._id === data.catalogId) ||
          (null as any)
        if (catalog) {
          const variant = catalog.variants.find(
            (v: any) => v.color.code === data.color && v.size.code === data.size
          )
          return variant
        }
      }
      return null
    }

    const onUploadFileDesign = (data: any) => {
      state.variantMapping.catalogs[data.indexCatalog].designs[data.index] = {
        designSrc: data.file.path,
        mockupSrc: '',
        position: '',
        name: '',
        width: '',
        height: '',
        template: '',
      }
    }

    const onClickVariant = (variant: any) => {
      state.variantMapping = { ...variant }
      let catalog = {
        catalogName: '',
        catalogProductCode: '',
        catalogId: '',
        quantity: 1,
        designs: [],
        catalogVariants: [],
      }
      if (
        state.variantMapping.catalogs &&
        state.variantMapping.catalogs.length === 0
      ) {
        state.variantMapping.catalogs = [
          ...state.variantMapping.catalogs,
          catalog,
        ]
      }
    }

    const onSubmitMapping = async () => {
      try {
        if (props.catalogs && props.product) {
          state.isLoadingAction = true
          let catalogs = state.variantMapping.catalogs.map((c: any) => {
            let cat = { ...c }
            const catalog =
              props?.catalogs?.find((ca: any) => ca._id === cat.catalogId) ||
              (null as any)
            let variant = catalog.variants.find(
              (v: any) => v.color.code === cat.color && v.size.code === cat.size
            )
            // delete cat.color;
            // delete cat.size;
            return {
              ...cat,
              catalogName: catalog.name,
              catalogProductCode: catalog?.productCode,
              catalogVariants: [
                {
                  _id: variant?._id || '',
                  name: `${variant?.name || ''}`,
                  code: `${variant?.catalogVariantCode || ''}`,
                  color: variant?.color,
                  size: variant?.size,
                  value: '',
                  productOption: '',
                },
              ],
            }
          })
          state.variantMapping.catalogs = catalogs
          let res = await onMappingProduct({
            productId: props?.product?._id || '',
            params: [state.variantMapping],
            mappingData: {
              type: 'mappingVariant',
              data: state.variantMapping,
            },
          })
          console.log('res', res)
          state.isLoadingAction = false
        }
        success()
      } catch (err) {
        error()
      }
    }

    return {
      state,
      imageUrl,
      onChangeCatalog,
      onChangeCatalogNumber,
      onSubmitMapping,
      getVariant,
      onUploadFileDesign,
      onClickVariant,
    }
  },
})
